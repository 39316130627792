@import '~www/themes/doctors/common/variables';

.b-btn {
  display: flex;
  flex: 1 1 0;
  background: $ui-kit-bg-gray-0;
  border: 1px solid $color-deep-soft-blue;
  box-shadow: 0 3px 5px rgba(151, 158, 167, 0.2);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  outline: none;
  text-decoration: none;
  padding: 8px;
  min-height: 40px;
  box-sizing: border-box;
  cursor: pointer;

  &_size-full {
    width: 100%;
  }

  &_secondary {
    background: #F0F2F3;
    border-radius: 5px;
    border: none;
    box-shadow: none;
  }

  &__text {
    font-style: normal;
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-primary;
  }

  &__icon {
    background: no-repeat center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: $ui-kit-primary;
  }

  &_timer {
    background: $color-ultra-deep-grey;
    border-radius: 5px;
    font-weight: $weight-medium;
    font-size: $font-size-body-2;
    line-height: $line-height-base;
    color: $ui-kit-text-secondary;
    border: none;
    box-shadow: none;

    &button:disabled {
      opacity: 1;
    }
  }

  &_back {
    background: $color-ultra-deep-grey;
    border: 1px solid $color-deep-soft-blue;
    box-shadow: none;
    position: relative;
    align-items: center;
    font-weight: $weight-medium;
    font-size: $font-size-body-2;
    line-height: $line-height-base;
    text-align: center;
    color: $color-neur-dark;

    & .b-btn__arrow {
      position: absolute;
      display: flex;
      align-items: center;
      left: 16px;

      .b-icon-chevron-right {
        fill: none;
      }
    }
  }

  &_is_disable {
    color: $color-dark-grey;
  }

  &_tabs {
    background: none;
    padding: 9px 0;
    border: none;
    box-shadow: none;
    text-decoration: underline dashed;
    min-height: initial;
  }

  &_margin_right {
    margin-right: 8px;
  }

  &_color {
    &_blue {
      border: none;
      box-shadow: none;
      box-sizing: border-box;
      background: $ui-kit-primary;
      font-weight: $weight-medium;
      font-size: $font-size-body-1;
      text-align: center;
      color: $ui-kit-bg-gray-0;
    }

    &_light {
      background: $ui-kit-bg-primary;
      border: none;
      border-radius: 5px;
      box-shadow: none;
      color: $ui-kit-primary;
      font-weight: $weight-medium;
      font-size: $font-size-body-2;
      line-height: $line-height-base;
    }

    &_light-blue {
      background: $ui-kit-bg-primary;
      border: none;
      color: $ui-kit-primary;
    }

    &_primary-border {
      border: 2px solid $ui-kit-primary;
      color: $ui-kit-primary;

      &:hover {
        border-color: darken($ui-kit-primary, 10);
        color: darken($ui-kit-primary, 10);
      }
    }
  }
}
