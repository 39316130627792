@import '~www/themes/doctors/common/keyframes';

.p-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__review {
    animation: fade-in-out 5s 4s infinite ease-in-out, fadeOut 1s 3s;
    min-height: 40px;
    margin-bottom: 36px;
  }

  @keyframes fade-in-out {
    0%,
    100% {
      opacity: 0;
    }

    20%,
    80% {
      opacity: 1;
    }
  }
}
