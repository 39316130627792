@import '~www/themes/doctors/common/variables';

.b-container {
  padding: 0 16px;

  &_height_fixed {
    height: 700px;
  }

  &_bg_white {
    background: $ui-kit-bg-gray-0;
    border-radius: 10px;
  }

  &_margin_bottom {
    margin-bottom: 24px;
  }

  &_margin_top {
    margin-top: 32px;
  }

  &_padding_min {
    padding: 0 8px;
  }

  &_fonts {
    font-family: $font-family-regular;
  }
}
