@import '~www/themes/doctors/common/variables';

.p-page {
  font-family: $font-family-regular;

  &_bg-color {
    &_white {
      background-color: $ui-kit-bg-gray-0;
    }
  }

  &_vertical_padding {
    padding: 16px 0;
  }

  &__unknown-doctor-link {
    color: $color-neur-dark;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    margin-top: 16px;
  }

  &__sub-title {
    font-size: 13px;
    font-weight: $weight-medium;
    line-height: 1.3em;
    color: $ui-kit-text-secondary;
  }

  &__title {
    font-size: 20px;
    font-weight: $weight-medium;
    line-height: 1.3em;
    color: $ui-kit-text;
    display: inline-block;
    margin: 0 0 8px;

    &::after {
      content: attr(data-counter);
      white-space: nowrap;
    }
  }

  &__title-count {
    font-size: $font-size-body-1;
    font-weight: $weight-regular;
    line-height: 1.3em;
    color: $ui-kit-text-secondary;
    margin-left: 8px;
  }

  &__title-page-num {
    color: $ui-kit-text-secondary;
    font-size: $font-size-lg;
    font-weight: $weight-regular;
  }

  &__intro-description {
    font-size: $font-size-body-2;
    line-height: 1.4em;
    font-weight: $weight-regular;
    color: $ui-kit-text-secondary;
    height: 70px;
    overflow: hidden;
    position: relative;
    margin: 0;

    &_text_center {
      text-align: center;
    }

    &_stock {
      height: 100px;
    }

    &_margin_top {
      margin-top: 16px;
    }

    &_color_grey {
      color: $color-dark-grey;
    }

    &_color_dark {
      color: $color-neur-dark;
    }

    &_height_auto {
      height: auto;
    }

    &_spec-info {
      height: 140px;
    }

    a {
      color: $ui-kit-primary;
      text-decoration: none;
    }
  }

  &__intro-description h2 {
    margin: 0 0 8px;
  }

  &__filter-btn-icon {
    background: url('/static/_v1/pd/icons/filter-icon.svg') no-repeat center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__filter-btn {
    background: #ECF1FB;
    border-radius: 5px;
    text-decoration: none;
    font-style: normal;
    font-weight: $weight-medium;
    font-size: $font-size-body-1;
    line-height: $line-height-base;
    color: $ui-kit-primary;
    display: flex;
    justify-content: center;
    padding: 15px 0;
    margin-top: 16px;
    border: 1px solid $ui-kit-primary;
  }

  &__buttons {
    background: $ui-kit-bg-gray-0;
    border: 1px solid $color-deep-soft-blue;
    border-radius: 5px;
    font-weight: $weight-medium;
    font-size: $font-size-body-2;
    line-height: $line-height-base;
    color: $color-neur-dark;
    text-align: center;
    padding: 15px;
    margin-top: 20px;
    display: block;
  }

  &__see-also-spec-link {
    background: #ECF1FB;
    border-radius: 5px;
    font-weight: $weight-medium;
    padding: 5px 10px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  &__show-full-text {
    margin-top: 10px;
    text-align: center;

    &_text-align_left {
      text-align: left;
    }

    &-button {
      font-size: $font-size-body-2;
      line-height: $line-height-base;
      color: $ui-kit-text-secondary;
      border-bottom: 1px dashed $ui-kit-text-secondary;
      display: inline-block;
      cursor: pointer;

      &_color_brand {
        color: $ui-kit-primary;
        border-bottom: 1px dashed $ui-kit-primary;
      }
    }
  }
}
