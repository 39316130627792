@import '~www/themes/doctors/common/variables';

.top-menu {
  &__inner {
    overflow: hidden;
    height: 44px;
  }

  &__track {
    overflow-x: auto;
    display: flex;
    padding: 0 8px;
    background-color: $ui-kit-bg-gray-0;
    height: 50px;
  }

  &__link {
    display: inline-block;
    padding: 8px 16px;
    border: 1px solid $ui-kit-bg-gray-80;
    border-radius: 86px;
    text-decoration-line: none;
    white-space: nowrap;
    height: 36px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
