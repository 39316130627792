/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* stylelint-disable-next-line keyframes-name-pattern */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
