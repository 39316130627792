
.suggest-medtochka-bottom-sheet {
  &__header {
    position: relative;
  }

  &__icon {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
